import { put, call, take, fork, race, cancel, select, takeLatest } from 'redux-saga/effects';
import 'firebase/messaging';
import 'firebase/analytics';
import firebase from 'firebase/app';
import { UpdateUser, UpdatePushSettings } from '../services/webAppEndPoint';
import createFirebaseChannel from './createFirebaseChannel';
import actions from './actions';
import { messagesActions } from '../messages';
import { conversationsActions } from '../conversations';
import types from './types';
import { visitorsActions } from '../visitors';
import { friendsActions } from '../friends';
import { conversationMessagesActions } from '../conversationMessages';
import { notificationsActions } from '../notifications';
import { loginTypes } from '../login';
import { profileSelectors, profileActions } from '../profile';
import { myGalleryActions } from '../myGallery';

function* firebaseListener() {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: 'AIzaSyCxrdY8oMcVzPsp_XHgXobkUFzqbcQ1y2U',
      authDomain: 'lovd-bb65c.firebaseapp.com',
      databaseURL: 'https://lovd-bb65c.firebaseio.com',
      projectId: 'lovd-bb65c',
      storageBucket: 'lovd-bb65c.appspot.com',
      messagingSenderId: '877267622651',
      appId: '1:877267622651:web:2946b18e0600c096046e5e',
      measurementId: 'G-EP8BF1Q144',
    });
    const analytics = firebase.analytics();
  } else {
    firebase.app();
  }
  const messaging = firebase.messaging();
  // const analytics = firebase.analytics();
  yield messaging.requestPermission();
  const fcmToken = yield messaging.getToken();
  
  try {
    yield call(UpdateUser, { fcmToken });
  } catch (error) {
    console.warn('Update FCM Token unsuccessfull ', error);
  }
  const firebaseChannel = yield call(createFirebaseChannel, messaging);
  while (true) {
    const item = yield take(firebaseChannel);
    const myId = yield select(profileSelectors.getProfileId);
    if (myId) {
      const { type, data, notificationType } = item;
      const { meta } = data;
      // const {
      //   meta: { receiptUserGuid },
      // } = data;
      if (!meta || myId === meta?.receiptUserGuid) {
        switch (type) {
          case 'text':
          case 'gift':
          case 'icebreaker':
            {
              const {
                hashId: conversationId,
                lastMessage: { id, text, lastDate: createdAt, userGuid: userId, type },
              } = data;

              yield put(conversationsActions.getUnread());
              yield put(
                messagesActions.receiveMessage({
                  conversationId,
                  id,
                  text,
                  createdAt,
                  userId,
                  type,
                })
              );
            }
            break;
          case 'match':
            {
              const { hash, isNewMatch, canAnswer, users, createdAt } = data;
              yield put(
                conversationsActions.addConversation({
                  id: hash,
                  isNewMatch,
                  canAnswer,
                  createdAt,
                  users: users
                    ? users.map(({ guid, firstname, age, picture, profile: { city } }) => ({
                        id: guid,
                        name: firstname,
                        age,
                        city,
                        image: { uri: picture },
                      }))
                    : undefined,
                })
              );
            }
            break;
          case 'visit':
            yield put(visitorsActions.addNewVisitor());
            break;
          case 'newfriend':
            yield put(friendsActions.getRequests());
            break;
          case 'newfriendaccepted':
            {
              const {
                userData: { guid: acceptedId, firstname: name, profilePicture: uri, age, city },
              } = data;
              yield put(friendsActions.rejectFriendSuccess(acceptedId));
              const newFriendData = {
                name,
                age,
                city,
                image: { uri },
                id: acceptedId,
              };
              yield put(friendsActions.addFriendSuccess(acceptedId, newFriendData));
            }
            break;
          case 'frienddeleted':
            {
              const { guid: deletedId } = data;
              yield put(friendsActions.removeFriendSuccess(deletedId));
            }
            break;
          case 'conversationdeleted':
            {
              const { hash: id } = data;
              yield put(conversationMessagesActions.deleteConversationSuccess(id));
            }
            break;
          case 'mediaUpload':
            {
              const { url: uri, objectGuid: id } = data;
              yield put(myGalleryActions.updateImage(id, { image: { uri } }));
            }
            break;
          default:
            break;
        }

        if (notificationType === 'opened') {
          const routeName = (() => {
            switch (type) {
              case 'text':
              case 'icebreaker':
                return 'Conversations';
              case 'gift':
                return 'Presents';
              case 'visit':
                return 'Visitor';
              case 'match':
                return 'Matches';
              default:
                return null;
            }
          })();
          if (routeName) {
            yield put(actions.setNavigation(routeName));
          }
        } else if (
          type !== 'frienddeleted' &&
          type !== 'conversationdeleted' &&
          type !== 'mediaUpload'
        ) {
          yield put(notificationsActions.addNotification(type, data));
        }
      }
    }
  }
}

function* handleInitListener() {
  let task = null;
  while (true) {
    const { logout, init } = yield race({
      logout: take(loginTypes.logout),
      init: take(types.initFirebase),
    });
    if (logout && task) {
      yield cancel(task);
      task = null;
    }
    if (init && !task) {
      task = yield fork(firebaseListener);
    }
  }
}

function* updatePermission({ permissions }) {
  try {
    yield call(UpdatePushSettings, permissions);
    yield put(actions.updatePermissionSuccess(permissions));
  } catch (error) {
    yield put(actions.updatePermissionFailure(error));
  }
}

function* handleUpdatePermission() {
  yield takeLatest(types.updatePermission, updatePermission);
}

export default { handleInitListener, handleUpdatePermission };
